<template>
  <WsUpdate
    :modelName="modelName"
    :id="_id"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="pageSetting.secondary"
    titleKey="title"
  >
  </WsUpdate>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_notice";
export default {
  metaInfo() {
    return {
      title: `編輯${this.label}`,
    };
  },
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["content"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: [
                "title",
                "description",
                "publish_at",
                "cover_image",
                "tags",
                "shop_notice_classes",
              ],
            },
          ],
        },
      ],
    },
  }),
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>